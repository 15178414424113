
.step-content {
    background: #fff;
    padding: 20px 30px;
    height: calc(100vh - 226px);
}
.step-content-header {
    font-weight: 500;
    .big {
        font-size: 28px;
    }
    .small {
        color: #959697;
        font-size: 16px;
        margin-left: 20px;
    }
}
.create-box {
    margin: 50px 0;
    padding: 40px 60px 80px 40px;
    background: #F5F5F5;
    /*width: 560px;*/
    width: calc(50% - 96px);
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
    .create-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        line-height: 1;
        font-size: 16px;
        & > .iconfont {
            color: #DFDFDF;
            font-size: 40px;
            transition: all .3s;
        }
        .right {
            font-size: 36px;
            display: flex;
            align-items: center;
            .iconfont {
                font-size: 30px;
            }
        }
        &.line2 {
            font-size: 24px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:hover {
        color: #FD4446;
        .create-item {
            & > .iconfont {
                color: #FD4446;
            }
        }
    }
}
